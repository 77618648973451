








































































































































import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import DniField from "@/components/DniFieldBusiness.vue";
import {
  Configuration,
  SelectedCountry,
  RedCapital,
  TitleTemplateDashboard
} from "@/configuration";
import API from "@/api";
import { EventBus } from "@/main";
import { mapState } from "vuex";
import { DniTrim } from "@/utils";

@Component({
  computed: { ...mapState(["user"]) },
  metaInfo: {
    title: "Crear empresa",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  data() {
    return {
      SelectedCountry,
      RedCapital,
      showCheck: {
        desc: false,
      },
      textAreaMax150: '',
      valid: true,
      loading: false,
      disabledName: true,
      rutValid: false,
      dialog: false,
      errorMessage: null,
      errorRut: null,
      errorPhone: null,
      errorDescription: null,
      errorRut404: null,
      rutInfo: null,
      rutInfo2: null,
      sale: [
        {
          id: 1,
          text: "Personas"
        },
        {
          id: 2,
          text: "Empresas"
        },
        {
          id: 3,
          text: "Ambas"
        }
      ],
      form: {
        name: "",
        dni: "",
        phone: null,
        address: "",
        debt: "",
        web: "",
        description: "",
        folder: null,
        razon_social: "",
        order: "",
        sales: null,
        id: this.$store.state.user.id
      },
      rules: {
        name: [(v: any) => !!v || "Nombre es requerido"],
        dni: [
          // @ts-ignore
          (v: any) => !!v || "Rut es requerido"
        ],
        phone: [
          (v: any) => !/[^\d]/gim.test(v) || "Tiene que ser solo numeros"
        ],
        address: [(v: any) => !!v || "Dirección es requerido"],
        debt: [(v: any) => !/[^\d]/gim.test(v) || "Tiene que ser solo numeros"],
        description: [
          (v: any) =>
            v.length <= 255 || "Descripción no puede exceder los 255 caracteres"
        ],
        noVacioTextAreaGeneral: [
          // @ts-ignore
          v => {
            if(!v || v.trim() === ''){
              return 'Campo requerido'
            }else if(v.trim().length < 10){
              return 'Ingrese al menos 10 caracteres'
            }else if(v.trim().length >= 150){
              this.$data.form.description = this.$data.form.description.trim();
              return true
            }else{
              return true
            }
          }
        ],
        sales: [(v: any) => !!v || "Selecciona una opción"],
      }
    };
  },
  watch: {
    "form.name"(val: any){
      this.$data.form.razon_social = val;
    },
    'form.description' (val: any){
      if(val != null && val.trim().length <= 150 && val.trim().length > 9){
        if(this.$data.textAreaMax150 != ''){
          this.$data.textAreaMax150 = '';
        }
        if(val.trim().length == 150){
          this.$data.textAreaMax150 = val 
        }
        this.$data.showCheck.desc = true;
      } else {
        this.$data.showCheck.desc = false;
      }
      if(val != null && val.trim().length > 150){
        this.$data.form.description = '';
        setTimeout(() => {
          this.$data.form.description = this.$data.textAreaMax150;	
        });
        this.$data.showCheck.desc = false;
      }
    }
  },
  methods: {
    validate() {
      // @ts-ignore
      if (this.$refs.form.validate()) {
        this.$data.valid = true;
        this.$data.errorMessage = null;

        let theTruth: any = {
          name: "nombre",
          dni: "rut",
          phone: "telefono",
          address: "direccion",
          debt: "deuda",
          web: "pagina_web",
          description: "descripcion",
          razon_social: "razon_social",
          sales: "empresa_target_id",
          id: "user_id"
        };

        let payload: any = {};

        theTruth['razon_social'] = 1;

        for (let key in this.$data.form) {
          if (theTruth[key] != undefined) {
            if (theTruth[key] == "rut") {
              payload[theTruth[key]] = DniTrim.string(this.$data.form[key]);
            } else {
              payload[theTruth[key]] = this.$data.form[key];
            }
          }
        }
        API.createBusiness(payload).then((res: any) => {
          this.$data.dialog = true;
          this.$store.commit("setBusiness", res.id);
          this.$store.commit("setEmpresasCount", +1);
          setTimeout(() => {
            this.$router.push({
              path: "/solicitud-credito"
            });
          }, Configuration.Redirection.timeout);
          API.createBusiness(payload)
            .then((res: any) => {
              this.$data.dialog = true;
              this.$store.commit("setBusiness", res.id);
              this.$store.commit("setEmpresasCount", +1);
              setTimeout(() => {
                this.$router.push({
                  path: "/panel/mis-empresas"
                });
              }, Configuration.Redirection.timeout);
            })
            .catch((err: any) => {
              this.$data.errorMessage = err.data.message;
              EventBus.$emit("snack-error", "Revisa los errores");
            });
        });
      }
    },
    fileUpload() {
      // @ts-ignore
      if (this.$refs.pdf.files.length > 0) {
        // @ts-ignore
        this.$data.form.folder = this.$refs.pdf.files[0];
      }
    }
  },
  components: {
    DniField
  }
})
export default class CreateBusiness extends Vue {}
